.root {
  margin-top: var(--max-icon-height);

  @media (--media-max-lg) {
    margin-top: var(--buy-button);
  }
}

.left-container {
  display: flex;
  align-items: flex-end;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.header-wrapper {
  @media (--media-max-sm) {
    display: flex;
    gap: var(--ye-spacing-rem-normal);
    align-items: flex-end;
    margin-bottom: var(--ye-spacing-rem-xlarge);
  }
}

.heading {
  margin-bottom: var(--ye-spacing-rem-xlarge);
  font-size: var(--ye-font-size-xhuge);
  font-weight: 700;
  line-height: var(--max-icon-height);

  @media (--media-max-sm) {
    margin-bottom: 0;
    font-size: var(--ye-font-size-xlarge);
    line-height: var(--ye-spacing-rem-huge);
  }
}

.heading-primary {
  color: var(--color-primary);
}

.heading-secondary {
  color: var(--color-secondary-orange);
}

.sub-heading {
  width: var(--full-width);
  max-width: var(--bannner-image-width);
  margin-bottom: var(--section-spacing);

  font-size: var(--ye-font-size-normal);
  font-weight: 400;

  @media (--media-max-sm) {
    margin-bottom: 0;
    font-size: var(--ye-font-size-small);
  }
}

.banner-image {
  width: var(--ui-component-normal);
  height: auto;

  @media (--media-max-lg) {
    width: var(--box-dimension);
  }

  @media (--media-max-sm) {
    width: var(--buy-button);
  }
}

/* right container */
.right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: var(--full-width);
  max-width: var(--banner-content-wrapper);
}

.banner-tile {
  display: flex;
  gap: var(--ye-spacing-rem-normal);
  margin-bottom: var(--ye-spacing-rem-xhuge);

  &:last-child {
    margin-bottom: 0;
  }
}

.banner-tile-wrapper {
  display: flex;
  flex-direction: column;
}

.banner-tile-heading {
  margin-bottom: var(--ye-spacing-rem-tiny);
  font-size: var(--ye-spacing-rem-normal);
  font-weight: 600;
}

.banner-tile-sub-heading {
  font-size: var(--ye-font-size-xsmall);
  font-weight: 400;
  color: var(--color-primary-c2-d1);
}
