.root {
  margin: var(--section-spacing-lg) 0;

  @media (--media-max-sm) {
    margin: var(--ye-spacing-rem-tiny) 0 var(--section-spacing);
  }
}

.feature-link-container {
  position: relative;

  display: flex;
  justify-content: space-between;

  height: var(--full-width);
  padding: 0 var(--ye-spacing-rem-large);

  @media (--media-max-xl) {
    padding: 0;
  }
}

.card {
  @mixin focusAndHover {
    transform: scale(1.3);

    & .feature-link-container {
      transform: scale(0.769);
    }
  }

  width: var(--full-width);
  height: var(--box-dimension-large);
  margin: 0 auto var(--scheme-select-width);

  border-radius: var(--ye-spacing-rem-xsmall);

  transition:
    width 0.6s,
    height 0.6s;

  &:last-child {
    margin-bottom: 0;
  }

  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  @media (--media-max-lg) {
    width: 90%;
    height: var(--desc-box-height);
    margin: 0 auto var(--section-spacing);
  }

  @media (--media-max-sm) {
    height: var(--card-dimension-mobile);
  }
}

.card-1 {
  background-color: var(--bg-color-audit-tool);
}

.card-2 {
  background-color: var(--bg-color-kbc-tool);
}

.card-3 {
  background-color: var(--bg-color-business-tool);
}

.card-4 {
  background-color: var(--bg-color-audit-tool);
}

.card-5 {
  background-color: var(--bg-color-retirement-tool);
}

.card-6 {
  background-color: var(--bg-color-business-tool);
}

.feature-link-container-left {
  flex-direction: row;
}

.feature-link-container-right {
  flex-direction: row-reverse;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--media-max-sm) {
    transform: translateY(var(--ye-spacing-rem-xlarge));
    justify-content: flex-start;
  }
}

.text-container-right {
  @media (--media-max-lg) {
    align-items: flex-end;
  }

  @media (--media-max-sm) {
    align-items: flex-start;
  }
}

.text-container-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
}

.subheading {
  margin-bottom: var(--ye-spacing-rem-xsmall);
  font-size: var(--ye-font-size-large);
  font-weight: 500;

  @media (--media-max-lg) {
    width: var(--half-size-dimension);
    max-width: var(--box-dimension-large);
    margin-bottom: var(--ye-spacing-rem-tiny);
    font-size: var(--ye-font-size-tiny);
  }

  @media (--media-max-sm) {
    width: var(--full-width);
    max-width: var(--section-spacing-lg);
  }
}

.subheading-1 {
  color: var(--color-secondary-orange);
}

.subheading-2 {
  color: var(--text-color-kbc-tool);
}

.subheading-3 {
  color: var(--text-color-business-tool);
}

.subheading-4 {
  color: var(--text-color-kids-tool);
}

.subheading-5 {
  color: var(--text-color-retirement-tool);
}

.subheading-6 {
  color: var(--text-color-kids-tool);
}

.heading {
  margin-bottom: var(--ye-spacing-rem-xsmall);
  font-size: var(--font-size-medium);
  font-weight: 700;
  color: var(--color-ink);

  @media (--media-max-lg) {
    width: var(--half-size-dimension);
    margin-bottom: var(--ye-spacing-rem-tiny);
    font-size: var(--ye-font-size-xlarge);
  }

  @media (--media-max-sm) {
    width: var(--full-width);
    max-width: var(--section-spacing-lg);
    font-size: var(--ye-font-size-large);
  }
}

.desc {
  width: var(--full-width);
  max-width: var(--newsletter-title-width);
  margin-bottom: var(--ye-spacing-rem-xlarge);

  font-size: var(--ye-font-size-large);
  font-weight: 400;
  color: var(--color-ink);

  @media (--media-max-lg) {
    max-width: var(--half-size-dimension);
    margin-bottom: var(--ye-spacing-rem-xsmall);
    font-size: var(--ye-font-size-tiny);
  }

  @media (--media-max-sm) {
    width: var(--full-width);
    max-width: var(--full-width);
  }
}

.button {
  padding: 0;
  font-size: var(--ye-font-size-large);
  color: var(--color-primary);

  @media (--media-max-lg) {
    width: var(--half-size-dimension);
    margin-bottom: var(--ye-spacing-rem-tiny);
    font-size: var(--ye-font-size-xsmall);
  }
}

.index {
  font-size: var(--ye-font-size-huge);
  font-weight: 700;
  color: var(--color-ink);

  @media (--media-max-lg) {
    font-size: var(--ye-font-size-normal);
  }
}

.feature-card {
  position: absolute;
  top: -14%;

  width: var(--box-dimension-large);
  height: var(--ui-component-normal);

  border-radius: var(--ye-spacing-rem-xsmall);

  @media (--media-max-lg) {
    width: var(--wrapper-dimension);
    height: var(--swiper-image-dimension);
  }

  @media (--media-max-sm) {
    width: var(--kids-goal-margin);
  }
}

.feature-card-right {
  right: 12%;

  @media (--media-max-xl) {
    right: 4%;
  }
}

.feature-card-left {
  left: 10%;

  @media (--media-max-xl) {
    left: 4%;
  }
}

.feature-card-1 {
  background-color: var(--card-color-audit-tool);
}

.feature-card-2 {
  background-color: var(--card-color-kbc-tool);
}

.feature-card-3 {
  background-color: var(--card-color-business-tool);
}

.feature-card-4 {
  background-color: var(--card-color-kids-tool);
}

.feature-card-5 {
  background-color: var(--card-color-retirement-tool);
}

.feature-card-6 {
  background-color: var(--card-color-rent-vs-buy-tool);
}

.feature-img {
  position: absolute;
  width: auto;
  height: var(--banner-image-height);

  @media (--media-max-lg) {
    height: var(--swiper-image-dimension);
  }

  @media (--media-max-sm) {
    height: var(--wrapper-dimension);
  }
}

.feature-img-0 {
  bottom: -2%;
  left: -12%;

  @media (--media-max-lg) {
    left: -8%;
  }
}

.feature-img-1 {
  bottom: -2%;
  left: -32%;
}

.feature-img-2 {
  bottom: 0;
  left: 30%;
}

.feature-img-3 {
  bottom: 0;
  left: -50%;

  @media (--media-max-lg) {
    left: -30%;
  }
}

.feature-img-4 {
  bottom: 0;
  left: -4%;

  @media (--media-max-lg) {
    left: 2%;
  }
}

.feature-img-5 {
  bottom: 0;
  left: -6%;

  @media (--media-max-lg) {
    left: -5%;
  }
}
