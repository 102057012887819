.banner-bg {
  display: flex;
  gap: var(--ye-spacing-rem-xhuge);

  /* TODO: replace image with css gradients */
  background: url("../../images/bannerBg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (--media-max-lg) {
    flex-direction: column;
  }
}
